import { createStore } from "vuex";
import localStorage from "@/helpers/localStorage";

export default createStore({
  state: {
    sounds: localStorage.readFromLocalStorage("sounds")
      ? localStorage.readFromLocalStorage("sounds")
      : true,
    theme: localStorage.readFromLocalStorage("theme")
      ? localStorage.readFromLocalStorage("theme")
      : "dark",
    notifications: localStorage.readFromLocalStorage("notifications")
      ? localStorage.readFromLocalStorage("notifications")
      : false,
    preferredVoice: localStorage.readFromLocalStorage("preferredVoice")
      ? localStorage.readFromLocalStorage("preferredVoice")
      : "",
    speech: localStorage.readFromLocalStorage("speech")
      ? localStorage.readFromLocalStorage("speech")
      : false,
  },
  getters: {
    sounds (state) {
      return (String(state.sounds).toLowerCase() === 'true')
    },

    theme (state) {
      return state.theme
    },

    notifications (state) {
      return (String(state.notifications).toLowerCase() === 'true')
    },

    speech (state) {
      return (String(state.speech).toLowerCase() === 'true')
    },

    preferredVoice (state) {
      return state.preferredVoice
    }
  },
  mutations: {
    setSounds (state, payload) {
      state.sounds = payload
      localStorage.writeToLocalStorage('sounds', payload)
    },

    setTheme (state, payload) {
      state.theme = payload
      localStorage.writeToLocalStorage('theme', payload)
    },

    setNotifications (state, payload) {
      state.notifications = payload
      localStorage.writeToLocalStorage('notifications', payload)
    },

    setSpeech (state, payload) {
      state.speech = payload
      localStorage.writeToLocalStorage('speech', payload)
    },

    setPreferredVoice (state, payload) {
      state.preferredVoice = payload
      localStorage.writeToLocalStorage('preferredVoice', payload)
    }
  },
});