import { connection } from "./jsstore_con";

export class TimerService {

    constructor() {
        this.tableName = "Timers";
    }

    getSuperTimers() {
        return connection.select({
            from: this.tableName,
            where: {
                parentId: -1,
            }
        })
    }

    getTimerById(timerId) {
        return connection.select({
            from: this.tableName,
            where: {
                id: timerId,
            }
        })
    }

    getSubtimersByParentId(timerId) {
        return connection.select({
            from: this.tableName,
            where: {
                parentId: timerId,
            },
        })
    }

    async getAllSubtimerDescendantsByParentId(timerId) {
        let descendants = []
        let directChildren = await this.getSubtimersByParentId(timerId)
        if (directChildren.length > 0){
            descendants.push(timerId)
            for (const child of directChildren){
                descendants.push(...await this.getAllSubtimerDescendantsByParentId(child.id))
            }
            return descendants
        }
        return [timerId]
    }

    addTimer(timer) {
        return connection.insert({
            into: this.tableName,
            values: [timer],
            return: true
        })
    }

    updateTimer(timer) {
        //parentId should never be editable
        return connection.update({
            in: this.tableName,
            set: {
                name: timer.name,
                type: timer.type,
                repetitions: timer.repetitions,
                count: timer.count,
                position: timer.position
            },
            where: {
                id: timer.id
            }
        })
    }

    removeTimerById(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }
}