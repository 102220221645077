<template>
  <navigation />
  <sounds />
  <notifications />
  <router-view v-slot="{ Component }" class="router-view">
    <transition name="scale" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
/* eslint-disable */
import Navigation from "@/components/global/Navigation.vue";
import Sounds from "@/components/global/Sounds.vue";
import Notifications from "@/components/global/Notifications.vue";
import { initJsStore } from "./service/idb_service";
import { Global } from "./global";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Navigation,
    Sounds,
    Notifications,
  },
  data() {
    return {};
  },
  async beforeCreate() {
    try {
      const isDbCreated = await initJsStore();
      if (isDbCreated) {
        console.log("db created");
        // prefill database
      } else {
        console.log("db opened");
      }
    } catch (ex) {
      console.error(ex);
      alert(ex.message);
      Global.isIndexedDbSupported = false;
    }
  },
  created() {
    this.emitter.on("changeTheme", (theme) => {
      this.changeTheme(theme);
    });

    this.changeTheme(this.theme);
  },
  computed: {
    ...mapGetters(["theme"]),
  },
  methods: {
    changeTheme(value) {
      document.body.className = value;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: var(--color-background);

  &.dark {
    --color-text: #fffafb;
    --color-highlight: #2e3333;
    --color-background: #131515;
    --color-affirmative: #8cc084;
    --color-negative: #dc8a7f;

    //nav specific
    --color-nav-icon: var(--color-text);
    --color-nav-highlight: var(--color-highlight);
    --color-nav-border: var(--color-highlight);
    --color-nav-background: var(--color-background);

    //timer controls specific
    --color-controls-background: var(--color-highlight);
    --color-controls-icon: var(--color-text);

    //toggle specific
    --color-toggle-border: var(--color-text);
    --color-toggle-switch: var(--color-text);
    --color-toggle-activated-bg: var(--color-affirmative);
    --color-toggle-activated-switch: var(--color-text);
  }

  &.light {
    --color-background: #fffafb;
    --color-highlight: #e2dedf;
    --color-text: #131515;
    --color-affirmative: #778da9;
    --color-negative: #ed6a5e;

    //nav specific
    --color-nav-icon: var(--color-text);
    --color-nav-highlight: var(--color-highlight);
    --color-nav-border: var(--color-text);
    --color-nav-background: var(--color-background);

    //timer controls specific
    --color-controls-background: var(--color-highlight);
    --color-controls-icon: var(--color-text);

    //toggle specific
    --color-toggle-border: var(--color-text);
    --color-toggle-switch: var(--color-text);
    --color-toggle-activated-bg: var(--color-affirmative);
    --color-toggle-activated-switch: var(--color-background);
  }

  &.coral {
    --color-background: #ed7964;
    --color-highlight: #f0a391;
    --color-text: #f6f7eb;
    --color-affirmative: #f6f7eb;
    --color-negative: #f6f7eb;

    //nav specific
    --color-nav-icon: var(--color-text);
    --color-nav-highlight: var(--color-highlight);
    --color-nav-border: var(--color-text);
    --color-nav-background: var(--color-background);

    //timer controls specific
    --color-controls-background: var(--color-highlight);
    --color-controls-icon: var(--color-text);

    //toggle specific
    --color-toggle-border: var(--color-text);
    --color-toggle-switch: var(--color-text);
    --color-toggle-activated-bg: var(--color-highlight);
    --color-toggle-activated-switch: var(--color-text);
  }

  &.sage {
    --color-background: #6b9080;
    --color-highlight: #a4c3b2;
    --color-text: #f6fff8;
    --color-affirmative: #8cc084;
    --color-negative: #f0a391;

    //nav specific
    --color-nav-icon: var(--color-text);
    --color-nav-highlight: var(--color-highlight);
    --color-nav-border: var(--color-text);
    --color-nav-background: var(--color-background);

    //timer controls specific
    --color-controls-background: var(--color-highlight);
    --color-controls-icon: var(--color-text);

    //toggle specific
    --color-toggle-border: var(--color-text);
    --color-toggle-switch: var(--color-text);
    --color-toggle-activated-bg: var(--color-highlight);
    --color-toggle-activated-switch: var(--color-text);
  }

  &.cosmic {
    --color-background: #0d1b2a;
    --color-highlight: #1b263b;
    --color-text: #e0e1dd;
    --color-affirmative: #8cc084;
    --color-negative: #f0a391;

    //nav specific
    --color-nav-icon: var(--color-text);
    --color-nav-highlight: var(--color-highlight);
    --color-nav-border: var(--color-highlight);
    --color-nav-background: var(--color-background);

    //timer controls specific
    --color-controls-background: var(--color-highlight);
    --color-controls-icon: var(--color-text);

    //toggle specific
    --color-toggle-border: var(--color-text);
    --color-toggle-switch: var(--color-text);
    --color-toggle-activated-bg: var(--color-affirmative);
    --color-toggle-activated-switch: var(--color-text);
  }

  &.nautical {
    --color-background: #415a77;
    --color-highlight: #778da9;
    --color-text: #f6fff8;
    --color-affirmative: #8cc084;
    --color-negative: #f0a391;

    //nav specific
    --color-nav-icon: var(--color-text);
    --color-nav-highlight: var(--color-highlight);
    --color-nav-border: var(--color-text);
    --color-nav-background: var(--color-background);

    //timer controls specific
    --color-controls-background: var(--color-highlight);
    --color-controls-icon: var(--color-text);

    //toggle specific
    --color-toggle-border: var(--color-text);
    --color-toggle-switch: var(--color-text);
    --color-toggle-activated-bg: var(--color-highlight);
    --color-toggle-activated-switch: var(--color-text);
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  background-color: var(--color-background);
  height: 100vh;
  width: 100vw;
  position: relative;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-tap-highlight-color: transparent;

  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.35s ease;
  }
  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }

  .router-view {
    max-width: 360px;
    height: calc(100vh - 300px);
    margin: 0px auto 0 auto;
    padding-top: 80px;
    position: relative;
  }
}

button,
fieldset,
input,
legend,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
