<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 8L8 24"
      stroke="#FFFAFB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 8L24 24"
      stroke="#FFFAFB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
    
<script>
export default {
  name: "Remove",
};
</script>

<style scoped>
path {
  stroke: var(--color-nav-icon);
}
</style>