<template>
  <div class="timers">
    <div class="timer">
      <div class="active-timer-name">{{ activeTimerName }}</div>
      <div class="count-container">
        <div class="digit">{{ hours[0] }}</div>
        <div class="digit">{{ hours[1] }}</div>
        <div class="delimiter">:</div>
        <div class="digit">{{ minutes[0] }}</div>
        <div class="digit">{{ minutes[1] }}</div>
        <div class="delimiter">:</div>
        <div class="digit">{{ seconds[0] }}</div>
        <div class="digit">{{ seconds[1] }}</div>
      </div>
      <div class="units-container">
        <div class="unit">hr</div>
        <div class="delimiter"></div>
        <div class="unit">min</div>
        <div class="delimiter"></div>
        <div class="unit">sec</div>
      </div>
    </div>
    <div class="controls-container">
      <button
        class="timer-control-btn pause-btn"
        @click="pauseTimer"
        v-if="timerActive && timerRunning"
      >
        <pause />
      </button>

      <button
        class="timer-control-btn play-btn"
        @click="resumeTimer"
        v-if="timerActive && !timerRunning"
      >
        <play class="play-icon" />
      </button>

      <button
        class="timer-control-btn cancel-btn"
        @click="resetTimerState"
        v-if="timerActive && !timerRunning"
      >
        <remove />
      </button>
    </div>

    <button
      class="create-new-btn"
      @click="createNewSuperTimer()"
      v-if="timers.length === 0 && getTimersFinished"
    >
      Create New
    </button>

    <TransitionGroup tag="div" name="fade" class="supertimers-list">
      <div
        v-for="(timer, index) of timers"
        class="supertimer"
        :key="timer.id"
        :style="{ '--i': index }"
      >
        <div class="supertimer-name">{{ timer.name }}</div>
        <button class="play-supertimer-btn" @click="playSuperTimer(timer.id)">
          <play />
        </button>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
/* eslint-disable */
import { TimerService } from "@/service/timer_service";
import Play from "@/components/icons/Play.vue";
import Pause from "@/components/icons/Pause.vue";
import Remove from "@/components/icons/Remove.vue";

export default {
  name: "Timer",
  components: {
    Play,
    Pause,
    Remove,
  },
  data() {
    return {
      service: new TimerService(),
      timers: [],
      descendantTimers: {},
      activeTimerSequence: [],
      indexInSequence: 0,
      timerActive: false, //means you've clicked to start playing a timer
      timerRunning: false, //means the timer should be actively decrementing
      activeTimerCount: 0,
      soundsEnabled: true,
      extraSounds: [1, 2],
      timerObject: null,
      getTimersFinished: false,
    };
  },
  created() {
    this.getAllSuperTimers();
  },
  beforeRouteLeave() {
    this.resetTimerState();
  },
  computed: {
    _seconds() {
      return 1;
    },
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },

    activeTimerName() {
      return this.timerActive ? this.activeTimer.name : "";
    },

    activeTimer() {
      return this.timerActive
        ? this.descendantTimers[this.activeTimerSequence[this.indexInSequence]]
        : {};
    },

    hours() {
      const hours = Math.floor(this.activeTimerCount / this._hours);
      if (hours <= 0) {
        return "00";
      }
      return hours > 9 ? String(hours) : "0" + hours;
    },

    minutes() {
      const minutes = Math.floor(
        (this.activeTimerCount % this._hours) / this._minutes
      );
      if (minutes <= 0) {
        return "00";
      }
      return minutes > 9 ? String(minutes) : "0" + minutes;
    },

    seconds() {
      const seconds = Math.floor(
        (this.activeTimerCount % this._minutes) / this._seconds
      );
      if (seconds <= 0) {
        return "00";
      }
      return seconds > 9 ? String(seconds) : "0" + seconds;
    },
  },
  methods: {
    async getAllSuperTimers() {
      try {
        this.timers = await this.service.getSuperTimers();
        this.getTimersFinished = true;
      } catch (ex) {
        alert(ex.message);
      }
    },

    async getTimer() {
      try {
        const timer = await this.service.getTimerById(this.timerId);
        return timer;
      } catch (ex) {
        alert(ex.message);
      }
    },

    getPositionOrderedDirectChildren(parentId) {
      const subtimers = Object.values(this.descendantTimers).filter(
        (timer) => timer.parentId === parentId
      );
      return subtimers.sort((a, b) => a.position - b.position);
    },

    async playSuperTimer(id) {
      this.resetTimerState();

      // get this timer and all the timers that are subtimers of this timer.
      // descendants is a list of descendant timer ids
      let descendants = await this.service.getAllSubtimerDescendantsByParentId(
        id
      );
      descendants.push(id);

      // put them in a javascript object where the key is the id and the value is the whole timer
      let descendantTimers = {};
      for (const timerId of descendants) {
        const timer = await this.service.getTimerById(timerId);
        descendantTimers[timerId] = timer[0];
      }
      this.descendantTimers = descendantTimers;

      // traverse this timer recursively and construct the sequence of ids that the timers should be played in
      this.activeTimerSequence = this.initTimerSequence(id);
      this.indexInSequence = 0;
      this.timerActive = true;

      this.activeTimerCount = this.activeTimer.count;
      this.timerRunning = true;
      this.startTimer();
    },

    initTimerSequence(id) {
      const timer = this.descendantTimers[id];
      if (timer.type === "container") {
        const singleSequence = [];
        const orderedChildren = this.getPositionOrderedDirectChildren(id);

        for (const child of orderedChildren) {
          const subtimerToSequence = this.initTimerSequence(child.id);
          singleSequence.push(...subtimerToSequence);
        }

        const timerRepsToArray = [];
        for (let i = 0; i < timer.repetitions; i++) {
          timerRepsToArray.push(...singleSequence);
        }

        return timerRepsToArray;
      } else {
        return Array(timer.repetitions).fill(timer.id);
      }
    },

    startTimer() {
      this.timerRunning = true;
      this.decrementTimer();
    },

    decrementTimer() {
      this.timerObject = setInterval(() => {
        if (this.activeTimerCount > 0 && this.timerRunning) {
          this.activeTimerCount--;
        }
      }, 1000);
    },

    resetTimerState() {
      //this essentially resets the state of the component to default
      clearInterval(this.timerObject);
      this.timerRunning = false;
      this.timerActive = false;
      this.activeTimerCount = 0;
      this.activeTimerSequence = [];
      this.indexInSequence = 0;
    },

    initNextActiveTimer() {
      this.activeTimerCount = this.activeTimer.count;
    },

    pauseTimer() {
      this.timerRunning = false;
    },

    resumeTimer() {
      if (this.timerRunning) {
        //if the timer was already running before we got in here it means we've already resumed
        return;
      }
      this.timerRunning = true;
    },

    async createNewSuperTimer() {
      this.$router.push({ name: "edit", query: { parentId: -1, position: 1 } });
    },
  },

  watch: {
    activeTimerCount(value) {
      if (value === 0) {
        //active timer finished
        if (this.timerActive) {
          // means that we havent cancelled the timer between ticks
          this.emitter.emit("playSound", "tone2");
          this.emitter.emit("notification", "Timer Complete");

          this.indexInSequence++;

          if (this.activeTimerSequence.length === this.indexInSequence) {
            //if we've exceeded the bounds of our timer, reset state
            this.resetTimerState();
          } else {
            this.initNextActiveTimer();
            this.emitter.emit("announceCurrentTimer", this.activeTimerName);
          }
        }
      } else {
        if (this.extraSounds.includes(value)) {
          this.emitter.emit("playSound", "tone1");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(10px);
}

.active-timer-name {
  width: 100%;
  text-align: center;
  height: 25px;

  font-size: 25px;
  font-style: italic;
  text-transform: uppercase;
  opacity: 60%;
}
.count-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 18px;

  font-weight: 700;
  font-size: 64px;

  .digit {
    display: grid;
    place-content: center;
    width: 45px;
  }

  .delimiter {
    display: grid;
    place-content: center;
    width: 20px;
  }
}
.units-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;

  .unit {
    display: grid;
    place-content: center;
    width: 90px;
    text-align: center;

    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    opacity: 60%;
  }
  .delimiter {
    display: grid;
    place-content: center;
    width: 20px;
  }
}
.controls-container {
  width: 200px;
  height: 60px;
  margin: 35px auto 0 auto;
  position: relative;

  .timer-control-btn {
    cursor: pointer;
    height: 60px;
    width: 60px;
    display: grid;
    place-content: center;
    border: none;
    background-color: var(--color-controls-background);
    border-radius: 50%;
    transition: 0.25s ease all;

    &:hover {
      transform: scale(1.1);
      opacity: 80%;
    }

    &.play-btn {
      position: absolute;
      left: 0;
      top: 0;

      .play-icon {
        padding-left: 5px;
      }
    }

    &.pause-btn {
      position: absolute;
      top: 0;
      margin-left: 70px;
      margin-right: 70px;
    }

    &.cancel-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.supertimers-list {
  margin-top: 53px;
  height: calc(100% - 335px); //335 is the height of the other stuff
  overflow-x: hidden;
  overflow-y: auto;
  .supertimer {
    width: 300px;
    margin: 10px auto 0 auto;
    padding-bottom: 4px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-highlight);

    .supertimer-name {
      font-size: 20px;
      padding: 8px;
    }

    .play-supertimer-btn {
      cursor: pointer;
      border: none;
      background-color: transparent;
      height: 36px;
      width: 36px;
      display: grid;
      place-content: center;

      :deep(svg) {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.create-new-btn {
  cursor: pointer;
  font-size: 20px;
  color: var(--color-text);
  height: 40px;
  border: none;
  background-color: transparent;
  display: grid;
  place-content: center;
  border-bottom: 1px solid var(--color-text);
  margin: 0 auto;
}
</style>
