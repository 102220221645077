<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 5.33333H8V26.6667H13.3333V5.33333Z"
      stroke="#FFFAFB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 5.33333H18.6667V26.6667H24V5.33333Z"
      stroke="#FFFAFB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
    
<script>
export default {
  name: "Pause",
};
</script>

<style scoped>
path {
  stroke: var(--color-nav-icon);
}
</style>