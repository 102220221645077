<template>
  <div class="sounds"></div>
</template>

<script>
/* eslint-disable */
import tone1 from "@/assets/sounds/tone1.mp3";
import tone2 from "@/assets/sounds/tone2.mp3";
import { mapGetters } from "vuex";
import { Howl } from "howler";

export default {
  name: "Sounds",
  computed: {
    ...mapGetters(["sounds", "speech", "preferredVoice"]),
  },
  data() {
    return {
      tone1: null,
      tone2: null,
      speechSynthesisVoices: null,
    };
  },
  created() {
    if (typeof window !== "undefined" && "speechSynthesis" in window) {
      this.setSpeechSynthesisVoices();
      if (window.speechSynthesis.addEventListener) {
        window.speechSynthesis.addEventListener(
          "voiceschanged",
          this.setSpeechSynthesisVoices
        );
      } else {
        const intervalId = setInterval(() => {
          const voices = window.speechSynthesis.getVoices();
          if (voices.length) {
            this.speechSynthesisVoices = voices;
            clearInterval(intervalId);
          }
        }, 100);
      }
    }

    this.tone1 = new Howl({
      src: [tone1],
      html5: true,
      volume: 0.5,
    });

    this.tone2 = new Howl({
      src: [tone2],
      html5: true,
      volume: 0.5,
    });

    this.emitter.on("playSound", (tone) => {
      if (!this.sounds) {
        return;
      }
      switch (tone) {
        case "tone1":
          this.tone1.play();
          break;
        case "tone2":
          this.tone2.play();
          break;
      }
    });

    this.emitter.on("announceCurrentTimer", (name) => {
      if (!this.speech) {
        return;
      }
      this.announceCurrentTimer(name);
    });
  },
  beforeDestroy() {
    if (typeof window !== "undefined" && "speechSynthesis" in window) {
      // Clean up the event listener when the component is destroyed
      window.speechSynthesis.removeEventListener(
        "voiceschanged",
        this.setSpeechSynthesisVoices
      );
    }
  },
  methods: {
    announceCurrentTimer(name) {
      if ("speechSynthesis" in window) {
        var msg = new SpeechSynthesisUtterance();
        const voice = this.speechSynthesisVoices.find(
          (v) => v.name === this.preferredVoice
        );
        if (voice) {
          msg.voice = voice;
        } else {
          this.speechSynthesisVoices.filter((v) => {
            let isEnglish = false;
            let isShortName = false;

            if (v.lang === "en-US" || v.lang === "en-GB") {
              isEnglish = true;
            }

            if (v.name.length < 10) {
              isShortName = true;
            }

            return isEnglish && isShortName;
          })[0]; //selects the first english voice found
        }
        msg.text = name;
        msg.volume = 1;
        msg.rate = 1.2;
        msg.pitch = 1;

        setTimeout(() => {
          window.speechSynthesis.speak(msg);
        }, 1000);
      }
    },

    setSpeechSynthesisVoices() {
      this.speechSynthesisVoices = window.speechSynthesis.getVoices();
    },
  },
};
</script>

<style lang="scss" scoped>
.sounds {
  display: none;
}
</style>
