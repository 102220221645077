<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28C21.8911 28 26.6667 23.2244 26.6667 17.3333C26.6667 11.4423 21.8911 6.66666 16 6.66666C10.109 6.66666 5.33337 11.4423 5.33337 17.3333C5.33337 23.2244 10.109 28 16 28Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 12V17.3333L18.6667 20"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66663 4L2.66663 8"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3334 8L25.3334 4"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00004 25.3333L5.33337 28"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 25.3333L26.6667 28"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Clock",
};
</script>

<style scoped>
path {
  stroke: var(--color-nav-icon);
}
</style>