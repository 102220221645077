import { createRouter, createWebHistory } from "vue-router";
import Timer from "../views/Timer.vue"

const routes = [
  {
    path: "/",
    name: "timer",
    component: Timer,
  },
  {
    path: "/timers",
    name: "timers",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Supertimers.vue"),
  },
  {
    path: "/timer/:id",
    name: "supertimer",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Supertimer.vue"),
  },
  {
    path: "/edit",
    name: "edit",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/EditTimer.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Settings.vue"),
  },
  {
    path: '/:catchAll(.*)', // Matches any undefined route
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
