<template>
  <div class="navigation-wrapper">
    <nav class="navigation">
      <router-link class="home" to="/">
        <clock />
      </router-link>

      <router-link class="timers" to="/timers">
        <plus />
      </router-link>

      <router-link class="settings" to="/settings">
        <settings />
      </router-link>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */
import Clock from "@/components/icons/Clock.vue";
import Plus from "@/components/icons/Plus.vue";
import Settings from "@/components/icons/Settings.vue";

export default {
  name: "Navigation",
  components: {
    Clock,
    Plus,
    Settings,
  },
};
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

nav {
  width: 255px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border: 2px solid var(--color-nav-border);
  background-color: var(--color-nav-background);
  border-radius: 8px;
  position: fixed;
  bottom: 50px;
  overflow: hidden;
  z-index: 5;

  a {
    display: grid;
    place-content: center;
    height: 55px;
    width: 85px;
    border-radius: 4px;
    transition: 0.5s ease;
    text-decoration: none;

    &.router-link-exact-active {
      background-color: var(--color-nav-highlight);
    }
  }
}
</style>
