<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66669 4L25.3334 16L6.66669 28V4Z"
      stroke="#FFFAFB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
  
<script>
export default {
  name: "Play",
};
</script>
  
<style scoped>
path {
  stroke: var(--color-nav-icon);
}
</style>