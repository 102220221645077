import { connection } from "./jsstore_con";
import { DATA_TYPE } from "jsstore";

const getDatabase = () => {
    const tblTimer = {
        name: 'Timers',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true,
            },
            parentId: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            type: {
                notNull: true,
                dataType: DATA_TYPE.String 
            },
            repetitions: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            count: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            position: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            }
        }
    };
    const dataBase = {
        name: "TIMER_APP_DB",
        tables: [tblTimer]
    };
    return dataBase;
};

export const initJsStore = async () => {
    const dataBase = getDatabase();
    return await connection.initDb(dataBase);
};