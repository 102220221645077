<template>
  <div class="notifications"></div>
</template>

<script>
/* eslint-disable */
import logo from "@/assets/logo.png";
import { mapGetters } from "vuex";

export default {
  name: "Notifications",
  computed: {
    ...mapGetters(["notifications"]),
  },
  created() {
    this.emitter.on("notification", (message) => {
      this.displayNotification(message);
    });
    this.serviceWorker = navigator.serviceWorker;
  },
  methods: {
    displayNotification(message) {
      if (
        window.Notification &&
        Notification.permission === "granted" &&
        this.notifications
      ) {
        this.notification(message);
      }
    },

    notification(message) {
      const options = {
        body: message,
        icon: logo,
      };
      this.serviceWorker.ready.then((registration) => {
        registration.showNotification("Timer Complete", options);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  display: none;
}
</style>
